import React from 'react';
import FooterWrapper from '../../styles/layout/FooterStyles';
// import EmailSubForm from './EmailSubForm';


const Footer = () => {
  return (
    <FooterWrapper>
      <small>&copy;{new Date().getFullYear()} 9ualia, Inc. </small>
    </FooterWrapper>
  );
};

export default Footer;
